<template>
    <div class="text-dark">
        <ModalHeader :left-text="'Kembali'" :middle-text="'Detail Transaksi'" :left-function="prevPage" />
        <div class="mt-5 pt-5">
            <BillingVirtualAccountBCA :detail-subscription="detailSubscription" :get-detail="getDetail" />
            <BillingVirtualAccountMandiri :detail-subscription="detailSubscription" :get-detail="getDetail" />
            <BillingQR :detail-subscription="detailSubscription" :get-detail="getDetail" />
        </div>
    </div>
</template>

<script>
import {
    BModal, BContainer, BRow, BCol, BFormGroup, BFormInput, BImg, BCard, BButton, BFormCheckbox, BFormRadio, BCollapse, VBToggle
} from 'bootstrap-vue'

import ModalHeader from '@/components/ModalHeader.vue';
import BillingVirtualAccountBCA from '@/components/Billing/BillingVirtualAccountBCA.vue';
import BillingVirtualAccountMandiri from '@/components/Billing/BillingVirtualAccountMandiri.vue'
import BillingQR from '@/components/Billing/BillingQR.vue';

export default {
    data() {
        return {
            detailSubscription: {},
        }
    },
    components: {
        ModalHeader,
        BillingVirtualAccountBCA,
        BillingVirtualAccountMandiri,
        BillingQR,
        BModal,
        BContainer,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BImg,
        BCard,
        BButton,
        BFormCheckbox,
        BFormRadio,
        BCollapse
    },
    directives: {
        'b-toggle': VBToggle,
    },
    created() {
        this.getDetail()
    },
    methods: {
        async getDetail() {
            const uuid = this.$route.params.id;
            this.$store.dispatch(`subscribe/getDetailSubscription`, {
                uuid: uuid,
            })
                .then(result => {
                    this.detailSubscription = result.data.data;
                }).catch(err => {
                    console.log(err);
                });
        },
        prevPage() {
            this.$router.push({ name: 'master-billing.index' });
        },
    }
}
</script>